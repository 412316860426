import { Link } from "gatsby";
import React from "react";

export default function Portfolio() {
  return (
    <>
      <section id="clients" className="section-bg">
        <div className="container">
          <div className="section-header">
            <h3>Our Portfolio</h3>
          </div>

          <div
            className="row g-0 clients-wrap clearfix"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            {/* row 1 and col 1 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link to="https://sterilwize.com/" target="_blank">
                  <img
                    src="/assets/img/portfolio3.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* col2 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div
                className="client-logo"
                style={{ borderLeft: "1px", borderLeftColor: "#EE4141" }}
              >
                <Link to="https://www.assurewize.com/" target="_blank">
                  <img
                    src="/assets/img/assure_wize.png"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* col3 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div
                className="client-logo"
                style={{ borderLeft: "1px", borderLeftColor: "#EE4141" }}
              >
                <Link to="https://wize.accountants/" target="_blank">
                  <img
                    src="/assets/img/wize_accountants_logo.png"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* col4 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link to="https://sterilwize.com/" target="_blank">
                  <img
                    src="/assets/img/portfolio8.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* row 2 and col 1 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link to="https://sterilwize.com/" target="_blank">
                <img
                  src="/assets/img/wizeFinance.png"
                  className="img-fluid"
                  alt=""
                />
                </Link>
              </div>
            </div>
             {/* col2 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link to="https://wize.health/" target="_blank">
                  <img
                    src="/assets/img/wizehealth.png"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* col3 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link to="https://wize.health/" target="_blank">
                  <img
                    src="/assets/img/portfolio1.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* col4 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                {/* <Link to="https://wize.health/" target="_blank">
                <img src="/assets/img/wizedelivery.png" className="img-fluid" alt="" />
              </Link> */}
                <img
                  src="/assets/img/wizedelivery.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            {/* row 3 and col 1 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link to="https://realtorswize.ca/" target="_blank">
                  <img
                    src="/assets/img/Wize_broker.png"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* col2 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link to="https://realtorswize.ca/" target="_blank">
                  <img
                    src="/assets/img/portfolio7.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
             {/* col3 */}
            <div
              className="col-lg-3 col-md-4 col-xs-6"
              // className="col-lg-3 col-md-4 col-xs-6"
              // style={{display: "inline-grid", width: "25%", marginRight: "auto"}}
            >
              <div className="client-logo">
                <img
                  src="/assets/img/Immi_wize.jpeg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
           {/* col4 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <img
                  src="/assets/img/portfolio6.jpeg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            {/* row 4 and col 1 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link
                  to="https://wize.health/pages/health-coach"
                  target="_blank"
                >
                  <img
                    src="/assets/img/portfolio9.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* col2 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link to="https://motivemates.com/" target="_blank">
                  <img
                    src="/assets/img/motivemate.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* col3 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link to="https://asapient.com/" target="_blank">
                  <img
                    src="/assets/img/portfolio2.png"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* col4 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div className="client-logo">
                <Link to="https://mindfulliving.ai/" target="_blank">
                  <img
                    src="/assets/img/portfolio4.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* row 5 and col 1 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div
                className="client-logo"
                style={{ borderLeft: "1px", borderLeftColor: "#EE4141" }}
              >
                <img
                  src="/assets/img/portfolio5.jpeg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            {/* col2 */}
            <div className="col-lg-3 col-md-4 col-xs-6">
              <div
                className="client-logo"
                style={{ borderLeft: "1px", borderLeftColor: "#EE4141" }}
              >
                <Link to="https://jatri.co" target="_blank">
                  <img
                    src="/assets/img/eilago_logo.png"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
           
            <div className="col-lg-3 col-md-4 col-xs-6"
              style={{ borderLeft: "0px", borderLeftColor: "none" }}
            >
              <div
                className="client-logo"
                style={{ borderLeft: "1px", borderLeftColor: "#EE4141" }}
              >
                <Link to="https://jatri.co" target="_blank">
                  <img
                    src="/assets/img/jatri-logo.png"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {/* <div className="row g-0 clients-wrap clearfix" data-aos="zoom-in" data-aos-delay="100">   */}

            {/* <div className="col-lg-3 col-md-4 col-xs-6" style={{borderLeft: "0px", borderLeftColor: "none",}}>
            <div className="client-logo">
              <Link to="https://motivemates.com/" target="_blank">
                <img src="/assets/img/motivemate.jpeg" className="img-fluid" alt="" />
              </Link>

            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6" style={{borderLeft: "0px", borderLeftColor: "none",}}>
            <div className="client-logo" style={{borderLeft: "1px", borderLeftColor: "#EE4141",}} >
              <Link to="https://asapient.com/" target="_blank">
                <img src="/assets/img/portfolio2.png" className="img-fluid" alt="" />
              </Link>
            </div>
          </div>
          
          <div className="col-lg-3 col-md-4 col-xs-6" style={{borderLeft: "0px", borderLeftColor: "none",}}>
          <div className="client-logo" style={{borderLeft: "1px", borderLeftColor: "#EE4141",}} >
              <Link to="https://alphablock.org/" target="_blank">
                <img src="/assets/img/alpha.png" className="img-fluid" alt="" />
              </Link>
            </div>
            
          </div> */}

            {/* col3 */}
            <div
              className="portfolio-techno portfolio-tech"
              // className="col-lg-3 col-md-4 col-xs-6"
              // style={{display: "inline-grid", width: "25%", marginRight: "auto"}}
            >
              <div className="client-logo">
                <Link to="https://tserves.com/" target="_blank">
                  <img
                    src="/assets/img/TechnoS.png"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>

            {/* </div> */}

            <div className="portfolio-techno portfolio-tech">
            <div className="client-logo">
                <Link to="https://alphablock.org/" target="_blank">
                  <img
                    src="/assets/img/alpha.png"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>

            {/* <div className="portfolio-techno portfolio-tech">
              <div className="client-logo">
                <Link to="https://mindfulliving.ai/" target="_blank">
                  <img
                    src="/assets/img/portfolio4.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div> */}
          </div>
        </div>

        <br />
        {/* <div className="row row-eq-height justify-content-center">
            <div className="col-lg-4 mb-4">
              <center>
                <Link to="/partnerwithus" className="btn lg-page-button">
                Become a Partner
                </Link>
              </center>
            </div>
          </div> */}
      </section>
    </>
  );
}
